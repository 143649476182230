<template>
  <div class="overflow-y-scroll scrollbar-hide h-screen">
    <div class="back-btn-wrapper">
      <button class="back-btn" @click="()=> this.$router.go(-1)">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
          <defs>
            <filter id="Rectangle_3158" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="3" result="blur"/>
              <feFlood flood-opacity="0.161"/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_12962" data-name="Group 12962" transform="translate(-308 -28)">
            <g id="Group_4717" data-name="Group 4717" transform="translate(317 33.547)">
              <g id="Group_4426" data-name="Group 4426" transform="translate(0 0.452)">
                <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_3158)">
                  <rect id="Rectangle_3158-2" data-name="Rectangle 3158" width="25" height="25" rx="12.5" transform="translate(9 6)" fill="#ff7400"/>
                </g>
              </g>
            </g>
            <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(317.705 34.376)" fill="#fff"/>
          </g>
        </svg>

      </button>
    </div>
    <div class="top-profile mt-40">
      <div class="bg-profile" style="top: -16px !important;"></div>
    </div>
    <div class="relative m-auto z-10 mb-28">
      <div class="flex flex-col justify-center items-center">
        <img class="w-24 h-24 rounded-full mb-4 logo-round" :src="baseURL + logo" alt="">
        <h5 style="white-space: nowrap ; top:-45px; font-size : 20px; color: #fff" >بنگاه {{name}}</h5>
      </div>


      <div class="mx-4 mb-5 mt-5">
        <div class="tab-profile-bongah">
          <ul>
            <li :class="activeTab === 1 ? 'active' : ''" @click="activeTab = 1"><button>مشخصات</button></li>
            <li :class="activeTab === 2 ? 'active' : ''" @click="activeTab = 2"><button>لیست املاک</button></li>
            <li :class="activeTab === 3 ? 'active' : ''" @click="activeTab = 3"><button>نظرات</button></li>
          </ul>
        </div>
        <Details
            :activity="activity"
            :phone="phone"
            :marker="marker"
            :licensePicture="license_picture"
            v-if="activeTab === 1"
        />
        <Estates v-if="activeTab === 2" />
        <Comments v-if="activeTab === 3" />

      </div>
    </div>
    <div class="contact-us w-full fixed bottom-0 flex justify-center py-2 z-50">
      <a :href="`tel:${phone}`" class="btn-custom w-4/5">
        تماس با بنگاه
        <span class="svg-btn-icon2 bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
      </a>
    </div>
  </div>
</template>

<script>
import Details from "./Details";
import Estates from "./EstatesCustomer";
import Comments from "./Comments";
import axios from "../../plugins/axios";
export default {
  name: "RealEstatePageCustomer",
  components :{
    Estates,
    Details,
    Comments
  },
  data(){
    return{
      activeTab : 1,
      activity : null,
      name : null,
      phone :null,
      license_picture : null,
      marker : null,
      baseURL: this.$store.state.general.baseURL
    }
  },
  mounted() {
    let id = this.$route.params.id
    axios.get('real-estates/' + id).then(({data}) => {
      this.marker = [data.data.geoposition.longitude, data.data.geoposition.latitude]
      this.activity = data.data.field_of_activity
      this.name = data.data.name;
      this.phone = data.data.telephone;
      this.logo = data.data.logo;
      this.license_picture = data.data.license_picture;
    })
  }
}
</script>

<style>

</style>